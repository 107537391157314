import React from "react";
import SonicIcons from "../sonicspace/assets/icons/icons";
import WPPIcons from "../wpp/assets/icons/icons";
import getBrandName from "../../utils/getBrandName";
import { brandConstants } from "../../utils/brandConstants";

const IconWrapper = ({ icon, ...props }) => {
  const brandName = getBrandName();

  switch (brandName) {
    case brandConstants.WPP:
      const WppIcon = WPPIcons?.[icon];
      return <WppIcon {...props} />;

    default:
      const SonicIcon = SonicIcons?.[icon];
      return <SonicIcon {...props} />;
  }
};
export default IconWrapper;
