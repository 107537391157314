import axios from "axios";
import { SET_NOTIFICATION_TOP_BAR } from "../common/redux/notificationTopBarSlice";
import { store } from "../reduxToolkit/store";
import getBrandId from "./getBrandId";
import getConfigJSON from "../common/helperFunctions/getConfigJSON";

const getPreLoginBanner = async ({
  isVisible = false,
  isClosed = false,
  msg = "",
}) => {
  const jsonConfig = await getConfigJSON();
  let ssDomainPath =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3002"
      : jsonConfig?.MUSIC_BANK_DOMAIN;

  axios(`${ssDomainPath}/api/banner/getBannerActiveForCSPreLogin`, {
    headers: {
      Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
      BrandName: getBrandId(),
    },
  }).then((res) => {
    store.dispatch(
      SET_NOTIFICATION_TOP_BAR({
        isVisible: !!res?.data?.bannerText,
        msg: res?.data?.bannerText,
        isClosed:
          isClosed && !!res?.data?.bannerText && res?.data?.bannerText === msg,
      })
    );
    console.log("res", res?.data?.bannerText);
  });
};

export default getPreLoginBanner;
