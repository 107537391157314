import { brandConstants } from "./brandConstants";

const getBrandName = () => {
  let LocalStotageBrandName = localStorage.getItem("brandName");
  if (!!LocalStotageBrandName) {
    return LocalStotageBrandName;
  }
  const BRAND_DOMAIN = document.location.origin;

  let googleDomains = process.env.REACT_APP_GOOGLE_DOMAINS?.split(",");
  let vodafoneDomains = process.env.REACT_APP_VODAFONE_DOMAINS?.split(",");
  let cocacolaDomains = process.env.REACT_APP_COCACOLA_DOMAINS?.split(",");
  let mercedesDomains = process.env.REACT_APP_MERCEDES_DOMAINS?.split(",");
  let mastercardDomains = process.env.REACT_APP_MASTERCARD_DOMAINS?.split(",");
  let shellDomains = process.env.REACT_APP_SHELL_DOMAINS?.split(",");
  let adacDomains = process.env.REACT_APP_ADAC_DOMAINS?.split(",");
  let wppDomains = process.env.REACT_APP_WPP_DOMAINS?.split(",");
  let bcgDomains = process.env.REACT_APP_BCG_DOMAINS?.split(",");
  let intelDomains = process.env.REACT_APP_INTEL_DOMAINS?.split(",");
  let ampDomains = process.env.REACT_APP_AMP_DOMAINS?.split(",");
  let sonicSpaceDomains = process.env.REACT_APP_SONICSPACE_DOMAINS?.split(",");
  let devDomains = process.env.REACT_APP_DEVELOPMENT_DOMAINS?.split(",");

  function isDomainPresent(domainArray) {
    return domainArray?.some(
      (domain) => !!domain && BRAND_DOMAIN?.endsWith(domain)
    );
  }
  let brandName;
  const {
    ADAC,
    AMP,
    BCG,
    COCACOLA,
    GOOGLE,
    INTEL,
    MASTERCARD,
    MERCEDES,
    SHELL,
    SONIC_SPACE,
    VODAFONE,
    WPP,
  } = brandConstants;
  // always add new brand's if statement at the top of if else block
  // as sonicspace/amp is the default brand which is placed at bottom of if else block
  if (isDomainPresent(googleDomains)) {
    brandName = GOOGLE;
  } else if (isDomainPresent(vodafoneDomains)) {
    brandName = VODAFONE;
  } else if (isDomainPresent(cocacolaDomains)) {
    brandName = COCACOLA;
  } else if (isDomainPresent(mercedesDomains)) {
    brandName = MERCEDES;
  } else if (isDomainPresent(mastercardDomains)) {
    brandName = MASTERCARD;
  } else if (isDomainPresent(shellDomains)) {
    brandName = SHELL;
  } else if (isDomainPresent(adacDomains)) {
    brandName = ADAC;
  } else if (isDomainPresent(wppDomains)) {
    brandName = WPP;
  } else if (isDomainPresent(bcgDomains)) {
    brandName = BCG;
  } else if (isDomainPresent(intelDomains)) {
    brandName = INTEL;
  } else if (isDomainPresent(sonicSpaceDomains)) {
    brandName = SONIC_SPACE;
  } else if (isDomainPresent(ampDomains)) {
    brandName = AMP;
  } else if (isDomainPresent(devDomains)) {
    brandName = SONIC_SPACE;
  } else {
    brandName = SONIC_SPACE;
  }
  if (process.env.NODE_ENV === "development") {
    brandName = SONIC_SPACE;
  }
  localStorage.setItem("brandName", brandName);
  return brandName;
};

export default getBrandName;
