import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "../../utils/messagesUtils";
import BrandingService from "../BrandingService";
import { BrandingContext } from "./BrandingContext";
import StylingProvider from "./StylingProvider";
import getBrandName from "../../utils/getBrandName";
import getWPPThemeJson from "../wpp/theme/getWPPThemeJson";
import FallBackPage from "../../common/components/FallBackPage/FallBackPage";
import "@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css";
import getConfigJSON from "../../common/helperFunctions/getConfigJSON";
import getCSUserMeta from "../../utils/getCSUserMeta";
import getBrandId from "../../utils/getBrandId";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  REMOVE_AUTH_STATE,
  SET_AUTH_META,
} from "../../modules/auth/redux/authSlice";
import axiosCSPrivateInstance from "../../axios/axiosCSPrivateInstance";
import { RESET_NOTIFICATION_TOP_BAR } from "../../common/redux/notificationTopBarSlice";
import { brandConstants } from "../../utils/brandConstants";
import generateCSTokenAndSSTokenAndLoadCSConfig from "../../utils/generateCSTokenAndSSTokenAndLoadCSConfig";

const BrandingProviderWPP = ({ children }) => {
  const [config, setConfig] = useState({ isDefault: true });
  const [messages, setMessages] = useState({ isDefault: true });
  const [jsonConfig, setJsonConfig] = useState({ isDefault: true });
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState({});
  const dispatch = useDispatch();

  let brandname = getBrandName();

  let shouldUseOsContext =
    brandname === brandConstants.WPP && process.env.NODE_ENV === "production";

  const generateCSToken = async (
    ssDomain,
    encodedEmail,
    redirectUrl,
    isSSOLogin
  ) => {
    if (!encodedEmail || !redirectUrl) {
      dispatch(REMOVE_AUTH_STATE());
      dispatch(RESET_NOTIFICATION_TOP_BAR());
      return;
    }
    let brandId = getBrandId();
    console.log("brandId", brandId);

    let ssDomainPath =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3002"
        : ssDomain;

    const token = await axios.get(
      `${ssDomainPath}/api/users/generateToken?user=${encodedEmail}`,
      {
        headers: {
          Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
          BrandName: brandId,
        },
      }
    );
    console.log("token", token?.data);

    const userMeta = await axios.get(
      `${ssDomainPath}/api/users/csUserData?user=${encodedEmail}`,
      {
        headers: {
          Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
          "Content-Type": "application/json",
          BrandName: brandId,
        },
      }
    );
    console.log("userMeta", userMeta?.data);
    dispatch(
      SET_AUTH_META({
        CSToken: token?.data?.token,
        authMeta: {
          username: userMeta?.data?.email,
          fullName: userMeta?.data?.full_name,
          id: 0,
          status: true,
        },
        appAccess: {
          AI_MUSIC: userMeta?.data?.aimusic_access,
          AI_VOICE: userMeta?.data?.aivoice_access,
          SS_ACCESS: userMeta?.data?.ss_access,
          AI_MUSIC_CREATE: userMeta?.data?.aiMusicCreateAccess,
          AI_MUSIC_EDIT: userMeta?.data?.aiMusicEditAccess,
          AI_MUSIC_VARIANT: userMeta?.data?.aiMusicGetVariantAccess,
        },
        brandMeta: {
          musicBankBrandName: userMeta?.data?.musicBankBrandName,
          tuneyBrandName: userMeta?.data?.tuneyBrandName,
          isPersonalizedTrackingAllowed:
            userMeta?.data?.isPersonalizedTrackingAllowed,
          redirectUrl,
          profanity: userMeta?.data?.profanityEnabled ?? false,
          isSSOLogin: isSSOLogin === "true",
        },
      })
    );
    return token?.data?.token;
  };

  useEffect(() => {
    setIsLoading(true);
    BrandingService.get().then(async (result) => {
      const jsonConfigMeta = await getConfigJSON();

      const { CSToken, authMeta } = getCSUserMeta();
      const urlParams = new URLSearchParams(window.location.search);
      const encodedEmailParam = !!urlParams.get("user")
        ? decodeURIComponent(urlParams.get("user"))
        : null;
      const isSSOParam = urlParams.get("is_sso");
      const redirectUrlParam = !!urlParams.get("redirect_url")
        ? decodeURIComponent(urlParams.get("redirect_url"))
        : null;
      console.log("isSSOParam", isSSOParam);
      const isSSOLoginParam = isSSOParam === "1" ? "true" : "false";
      console.log("isSSOLoginParam", isSSOLoginParam);

      if (isSSOLoginParam) {
        localStorage.setItem("isSSOLogin", "true");
      } else {
        localStorage.setItem("isSSOLogin", "false");
      }
      if (!!encodedEmailParam) {
        localStorage.setItem("user", encodedEmailParam);
      }
      if (!!redirectUrlParam) {
        localStorage.setItem("redirectUrl", redirectUrlParam);
      }

      const encodedEmail = encodedEmailParam || localStorage.getItem("user");
      const redirectUrl =
        redirectUrlParam || localStorage.getItem("redirectUrl");
      const isSSOLogin = isSSOLoginParam || localStorage.getItem("isSSOLogin");
      console.log("user", encodedEmail);
      console.log("redirectUrl", redirectUrl);

      console.log("CSToken", CSToken);
      console.log("authMeta?.username", authMeta?.username);

      let checkIfTokenValid = false;
      if (CSToken) {
        try {
          let tokenData = await axiosCSPrivateInstance.get(`/user/getUserData`);
          console.log("tokenData", tokenData?.data);
          if (!!tokenData?.data?.id) {
            checkIfTokenValid = true;
          } else {
            checkIfTokenValid = false;
          }
        } catch (error) {
          checkIfTokenValid = false;
          console.log("error");
        }
      }

      const decodedBase64 = decodeURIComponent(encodedEmail);
      const decodedEmail = atob(decodedBase64);

      console.log("decodedBase64", decodedBase64);
      console.log("decodedEmail", decodedEmail);
      console.log("checkIfTokenValid", checkIfTokenValid);

      if (
        !CSToken ||
        authMeta?.username !== decodedEmail ||
        !checkIfTokenValid
      ) {
        await generateCSTokenAndSSTokenAndLoadCSConfig(
          jsonConfigMeta?.MUSIC_BANK_DOMAIN,
          encodedEmail,
          redirectUrl,
          isSSOLogin
        );
      }

      let configResult = result[0];
      let messagesResult = result[1];
      let themeResult;
      const csStringThemeData = localStorage.getItem(`csThemeData`);
      if (shouldUseOsContext && !!csStringThemeData) {
        try {
          const csJSONThemeData = JSON.parse(csStringThemeData);
          // console.log("csJSONThemeData", csJSONThemeData);
          let themeObj = getWPPThemeJson(csJSONThemeData);
          // console.log("if cs provider BrandingProviderWPP::themeObj", themeObj);
          themeResult = themeObj;
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // console.log("else getting in branding component");
        themeResult = result[0]?.theme;
      }

      updateStatesAndCallSetBodyFonts1(
        configResult,
        themeResult,
        messagesResult,
        jsonConfigMeta
      );
    });
  }, []);

  const setBodyFonts = () => {
    // console.log("BrandingProviderWPP::setBodyFonts:::config CS", config.fonts);
    var fonts = document.createElement("style");
    fonts.appendChild(document.createTextNode(config.fonts));
    document.head.appendChild(fonts);
  };

  async function updateStatesAndCallSetBodyFonts1(
    configResult,
    themeResult,
    messagesResult,
    jsonConfigMeta
  ) {
    // console.log(
    //   "BrandingProviderWPP CS::updateStatesAndCallSetBodyFonts1:::configResult",
    //   configResult
    // );
    await Promise.all([
      setConfig(configResult),
      setTheme(themeResult),
      setMessages(messagesResult),
      setJsonConfig(jsonConfigMeta),
      setIsLoading(false),
    ]);

    setBodyFonts();
    // console.log(
    //   "BrandingProviderWPP::setBodyFonts:::config CS after",
    //   config.fonts
    // );
  }

  const renderLoading = () => <FallBackPage />;

  if (isLoading) {
    return renderLoading();
  }

  const state = {
    config,
    messages,
    theme,
    jsonConfig,
  };

  return (
    <BrandingContext.Provider value={state}>
      <IntlProvider locale="en" messages={flattenMessages(messages)}>
        <StylingProvider stylingVariables={theme}>{children}</StylingProvider>
      </IntlProvider>
    </BrandingContext.Provider>
  );
};

export default BrandingProviderWPP;
