import React from "react";
import SonicModal from "../sonicspace/components/Modal/SonicModal";
import WPPModal from "../wpp/components/Modal/WPPModal";
import getBrandName from "../../utils/getBrandName";
import { brandConstants } from "../../utils/brandConstants";

const ModalWrapper = (props) => {
  const brandName = getBrandName();

  switch (brandName) {
    case brandConstants.WPP:
      return <WPPModal {...props} />;

    default:
      return <SonicModal {...props} />;
  }
};
export default ModalWrapper;
