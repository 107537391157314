export const brandConstants = {
  AMP: "amp",
  SONIC_SPACE: "sonicspace",
  INTEL: "intel",
  BCG: "bcg",
  WPP: "wpp",
  ADAC: "adac",
  SHELL: "shell",
  MASTERCARD: "mastercard",
  MERCEDES: "mercedes",
  COCACOLA: "cocacola",
  VODAFONE: "vodafone",
  GOOGLE: "google",
};
