import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  voicesList: [],
  favVoicesList: [],
};

const voicesListSlice = createSlice({
  name: "SELECTED_VOICES",
  initialState,
  reducers: {
    ADD_VOICES_LIST: (state, action) => {
      state.voicesList = action.payload;
    },
    REMOVE_VOICE_LIST: (state) => {
      state.voicesList = [];
    },
    SET_FAV_VOICES_LIST: (state, action) => {
      state.favVoicesList = action.payload;
    },
  },
});

export default voicesListSlice.reducer;
export const { ADD_VOICES_LIST, REMOVE_VOICE_LIST, SET_FAV_VOICES_LIST } =
  voicesListSlice.actions;
