import getBrandName from "./getBrandName";

const getBrandId = () => {
  let brandName = getBrandName();

  const { REACT_APP_BRAND_ID } = process.env;

  let brandId;
  brandId =
    process.env?.[`REACT_APP_BRAND_ID_${brandName.toUpperCase()}`] ||
    REACT_APP_BRAND_ID;
  if (process.env.NODE_ENV === "development") {
    brandId = 2;
  }
  return brandId;
};

export default getBrandId;
