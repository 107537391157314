import axios from "axios";
import { RESET_NOTIFICATION_TOP_BAR } from "../common/redux/notificationTopBarSlice";
import {
  REMOVE_AUTH_STATE,
  SET_AUTH_META,
} from "../modules/auth/redux/authSlice";
import getBrandId from "./getBrandId";
import { store } from "../reduxToolkit/store";

const generateCSTokenAndSSTokenAndLoadCSConfig = async (
  ssDomain,
  encodedEmail,
  redirectUrl,
  isSSOLogin
) => {
  try {
    if (!encodedEmail || !redirectUrl) {
      store.dispatch(REMOVE_AUTH_STATE());
      store.dispatch(RESET_NOTIFICATION_TOP_BAR());
      return;
    }
    let brandId = getBrandId();

    let ssDomainPath =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3002"
        : ssDomain;

    const CStoken = await axios.get(
      `${ssDomainPath}/api/users/generateToken?user=${encodedEmail}`,
      {
        headers: {
          Authorization: "Basic cmVzdC1jbGllbnQ6cmVzdC1jbGllbnQtc2VjcmV0",
          BrandName: brandId,
        },
      }
    );

    const SStoken = await axios.get(
      `${window.location.origin}/api/user/ss_token`,
      {
        headers: {
          Authorization: `Bearer ${CStoken?.data?.token}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log("SStoken", SStoken?.data?.access_token);

    const userMeta = await axios.get(
      `${ssDomainPath}/api/users/csUserData?user=${encodedEmail}`,
      {
        headers: {
          Authorization: `Bearer ${SStoken?.data?.access_token}`,
          "Content-Type": "application/json",
          BrandName: brandId,
        },
      }
    );

    store.dispatch(
      SET_AUTH_META({
        CSToken: CStoken?.data?.token,
        SSToken: SStoken?.data?.access_token,
        authMeta: {
          username: userMeta?.data?.email,
          fullName: userMeta?.data?.full_name,
          id: 0,
          status: true,
        },
        appAccess: {
          AI_MUSIC: userMeta?.data?.aimusic_access,
          AI_VOICE: userMeta?.data?.aivoice_access,
          SS_ACCESS: userMeta?.data?.ss_access,
          AI_MUSIC_CREATE: userMeta?.data?.aiMusicCreateAccess,
          AI_MUSIC_EDIT: userMeta?.data?.aiMusicEditAccess,
          AI_MUSIC_VARIANT: userMeta?.data?.aiMusicGetVariantAccess,
        },
        brandMeta: {
          musicBankBrandName: userMeta?.data?.musicBankBrandName,
          tuneyBrandName: userMeta?.data?.tuneyBrandName,
          // musicBankBrandName: "Mastercard",
          // tuneyBrandName: "Mastercard",
          isPersonalizedTrackingAllowed:
            userMeta?.data?.isPersonalizedTrackingAllowed,
          SSDomainPath: ssDomainPath,
          redirectUrl,
          profanity: userMeta?.data?.profanityEnabled ?? false,
          isSSOLogin: isSSOLogin === "true",
        },
      })
    );
    return CStoken?.data?.token;
  } catch (error) {
    console.log("generateCSTokenAndSSTokenAndLoadCSConfig error :: ", error);
  }
};

export default generateCSTokenAndSSTokenAndLoadCSConfig;
