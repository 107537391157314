import React, { useEffect } from "react";
import getBrandName from "../../../utils/getBrandName";
import NavStrings from "../../../routes/constants/NavStrings";

const AppTitleProvider = () => {
  let pathname = window.location.pathname;

  useEffect(() => {
    applyDocumentTitle();
  }, [pathname]);

  const applyDocumentTitle = () => {
    let pageTitle;
    let brandNameTitle = getBrandName().toUpperCase();
    switch (pathname) {
      case NavStrings.HOME:
        pageTitle = "Home";
        break;
      case NavStrings.PROJECTS:
        pageTitle = "My Projects";
        break;
      case (pathname.match(NavStrings.WORKSPACE) || {}).input:
        pageTitle = "WorkSpace";
        break;
      default:
        pageTitle = "";
        break;
    }
    let toolName = brandNameTitle === "MASTERCARD" ? "Sonic Station" : "Creation Station";
    if (pageTitle) {
      document.title = pageTitle + " - "+toolName+" | " + brandNameTitle;
    } else {
      document.title = toolName+" | " + brandNameTitle;
    }
  };
  return <></>;
};

export default AppTitleProvider;
