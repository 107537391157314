import getBrandName from "../utils/getBrandName";

class BrandingService {
  constructor() {}

  loadBrandCSS = async (brandName) => {
    try {
      let CSShref = await import(`./${brandName}/theme/style.css`);
      var brandCSS = document.createElement("link");
      brandCSS.rel = "stylesheet";
      brandCSS.href = CSShref;
      document.head.appendChild(brandCSS);
    } catch (error) {
      console.log("error", error);
    }
  };

  async get() {
    let brandName = getBrandName();
    // console.log("brandName", brandName);
    try {
      this.loadBrandCSS(brandName);
      let config = await import(`./${brandName}/config.js`);
      let messages = await import(`./${brandName}/messages/messages.js`);
      return [config.default, messages.default];
    } catch (error) {
      console.error("error while loading config,theme and messages");
    }
  }
}

export default new BrandingService();
