import axios from "axios";
import getBrandName from "../../utils/getBrandName";

const getConfigJSON = async () => {
  let brandName = getBrandName();
  let baseURL = document.location.origin;
  let configPath = process.env.REACT_APP_CONFIG_FOLDER_PATH;
  let configUrl;

  try {
    let configMeta;
    if (process.env.NODE_ENV === "development") {
      configUrl = `${baseURL}/config/localServer/dev.json?t=${Date.now()}`;
    } else if (origin === "https://creationstation.sonic-hub.com") {
      configUrl = `${origin}/config/${configPath}/sonicspaceDefault.json?t=${Date.now()}`;
    } else {
      configUrl = `${baseURL}/config/${configPath}/${brandName}.json?t=${Date.now()}`;
    }
    configMeta = await axios(configUrl);

    return configMeta?.data;
  } catch (error) {
    console.log("// error", error);
  }
};

export default getConfigJSON;
