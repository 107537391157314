import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  genre:"",
  value:"",
  cues:[],
};
const MGSlice = createSlice({
    name: "MG",
    initialState,
    reducers: {
        SET_GENRE: (state, action) => {
        state.genre = action.payload;
      },
        SET_MOOD: (state, action) => {
        state.mood = action.payload;
      },
      ADD_CUES: (state, action) => {
        state.cues = action.payload;
      },
    },
  });
  export const { SET_GENRE, SET_MOOD,ADD_CUES } = MGSlice.actions;
  export const selectgenre = (state) => state.genre;
  export const selectmood = (state) => state.mood;
  export default MGSlice.reducer;
 